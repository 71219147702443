const url_prefix = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const backend = {
  createAdventure: async (name) => {
    return await fetch(`${url_prefix}/adventure`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      });
  },

  loadAdventure: async (adventureId) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}`);
  },

  insertItem: async (adventureId, item) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/items`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
      });
  },

  updateItem: async (adventureId, item) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/items/${item.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
      });
  },

  deleteItem: async (adventureId, itemId) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/items/${itemId}`,
      {
        method: 'DELETE'
      });
  },

  archiveItem: async (adventureId, itemId, value) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/items/${itemId}/archived`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ archived: value })
      });
  },

  starItem: async (adventureId, itemId, value) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/items/${itemId}/starred`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ starred: value })
      });
  },

  uploadImage: async (file) => {
    const formData = new FormData();
    formData.append("image", file, file.name);
    return await fetch(`${url_prefix}/images`,
      {
        method: 'POST',
        body: formData
      });
  },

  getImageUrl: (file) => {
    return `${url_prefix}/files/${file}`;
  },

  link: async (adventureId, fromItemId, toItemId) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/links`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fromItemId, toItemId })
    });
  },

  unlink: async (adventureId, fromItemId, toItemId) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/unlink`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fromItemId, toItemId })
    });
  },

  addMarker: async (adventureId, itemId, linkItemId, name, x, y) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/items/${itemId}/markers`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ linkItemId, x, y, name })
    });
  },

  removeMarker: async (adventureId, itemId, markerId) => {
    return await fetch(`${url_prefix}/adventure/${adventureId}/items/${itemId}/markers/${markerId}`,
    {
      method: 'DELETE'
    });
  }
};

export default backend;