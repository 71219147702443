import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCaretLeft, faSquareCaretRight } from '@fortawesome/free-regular-svg-icons';
import './ItemBox.css';
import EditItem from './EditItem';
import ViewItem from './ViewItem';
import LinkItem from './LinkItem';

const headings = {
  'quest': 'Quest',
  'personororg': 'Person or Organisation',
  'miscitem': 'Miscellaneous item',
  'place': 'Place',
  'logentry': 'Log entry'
}

function ItemBox({item, itemLookupTree, cancel, save, deleteItem, toggleStarItem, toggleArchiveItem, link, addLink, removeLink, uploadImage, imageUrl, zoomImage, flash, allItems}) {
  const [state, setState] = useState("viewing");
  const [expanded, setExpanded] = useState(false);
  const [lastFlash, setLastFlash] = useState(-1);
  
  useEffect(() => {
    setState(item.id === null ? "editing" : "viewing");
    setExpanded(false);
  }, [item.id])

  const shouldFlash = (lastFlash !== flash);
  if (shouldFlash) {
    const newFlash = flash;
    setTimeout(() => setLastFlash(newFlash), 300);
  }

  return <div className={"itembox " + item.type + " " + (expanded ? "expanded" : "") + " " + (item.archived ? "archived" : "") + " " + (shouldFlash ? "flash" : "")}>
    <div className="innercontainer">
      <div className="formrow">
        <span className="type">{headings[item.type]}</span>
        <div className="resize" onClick={() => setExpanded(!expanded)}><FontAwesomeIcon icon={expanded ? faSquareCaretLeft : faSquareCaretRight}></FontAwesomeIcon></div>
      </div>
      {
        {
          "editing": () => <EditItem item={item} cancel={() => { setState("viewing"); if (item.id === null) { cancel(null); } }} uploadImage={uploadImage} save={(item) => { setState("viewing"); save(item); }} />,
          "viewing": () => <ViewItem item={item} itemLookupTree={itemLookupTree} close={() => cancel(item.id)} edit={() => setState("editing")} editLinks={() => setState("linking")} deleteItem={deleteItem} toggleArchiveItem={toggleArchiveItem} toggleStarItem={toggleStarItem} link={link} imageUrl={imageUrl} zoomImage={zoomImage} />,
          "linking": () => <LinkItem item={item} itemLookupTree={itemLookupTree} close={() => cancel(item.id)} back={() => setState("viewing")} link={link} addLink={addLink} removeLink={removeLink} allItems={allItems} />,
        }[state]()
      }
    </div>
  </div>;
}

export default ItemBox;