import { useState, useCallback } from 'react';
import './Search.css';

function Search({quests, peopleAndOrgs, places, misc, logEntries, openItem, close}) {
  const [searchString, setSearchString] = useState("");
  const [searchResult, setSearchResult] = useState([...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries].map(item => (
    {
      item,
      excerpt: item.text.substring(0, 80)
    }
  )).sort((a, b) => a.item.name.localeCompare(b.item.name)));

  const search = useCallback((e) => {
    setSearchString(e.target.value);
    if (e.target.value.trim() === '') {
      setSearchResult([...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries].map(item => (
        {
          item,
          excerpt: item.text.substring(0, 80)
        }
      )).sort((a, b) => a.item.name.localeCompare(b.item.name)));
      return;
    }
    const lowercaseSearchString = e.target.value.toLowerCase();
    const newSearchResult = [];
    const handleItem = (item) => {
      let textHit = item.text.toLowerCase().indexOf(lowercaseSearchString);
      let nameHit = item.name.toLowerCase().indexOf(lowercaseSearchString);
      if (textHit > -1) {
        textHit = Math.max(0, textHit - 25);
        const excerpt = item.text.substring(textHit, textHit + 80);
        newSearchResult.push({ item, excerpt: (textHit === 0 ? "" : "\u2026") + excerpt, nameHit: nameHit > -1 });
      } else if (nameHit > -1) {
        newSearchResult.push({ item, excerpt: item.text.substring(0, 80), nameHit: nameHit > -1 });
      }
    };
    for (const item of quests) {
      handleItem(item);
    }
    for (const item of peopleAndOrgs) {
      handleItem(item);
    }
    for (const item of places) {
      handleItem(item);
    }
    for (const item of misc) {
      handleItem(item);
    }
    for (const item of logEntries) {
      handleItem(item);
    }
    if (newSearchResult.length === 0) {
      newSearchResult.push({ item: { id: undefined, name: "No results" }, excerpt: "" });
    }
    setSearchResult(newSearchResult.sort((a, b) => a.nameHit === b.nameHit ? a.item.name.localeCompare(b.item.name) : (a.nameHit ? -1 : 1)));
  }, [setSearchString, quests, peopleAndOrgs, places, misc, logEntries]);

  return <div className="searchbackdrop" onClick={close}>
    <div className="search" onClick={(e) => e.stopPropagation()}>
      <div className="searchbox"><input type="text" value={searchString} placeholder="Search..." autoFocus onChange={search}></input></div>
      <div className="searchresults">
        {
          searchResult.map(result =>
            <div key={result.item.id} className={"searchitem " + (result.item.type ?? "")} onClick={() => result.item.id && openItem(result.item)}>
              <div className="resultname">{result.item.name}</div>
              <div className="resultexcerpt">{result.excerpt}</div>
            </div>
          )
        }
      </div>
    </div>
  </div>;
}

export default Search;