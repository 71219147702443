import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMaximize, faMinimize } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import './SideList.css';

function SideList({extraClass, heading, isFocused, addClick, selectClick, items, showProperty, focus}) {
  return <div className={"sidelist " + extraClass}>
    <div className="innercontainer">
      <div className="listheader" onClick={focus}><span>{heading}</span><span className="icon"><FontAwesomeIcon icon={isFocused ? faMinimize : faMaximize} /></span><div className="listadd" onClick={(e) => { addClick(); e.stopPropagation(); }}>+</div></div>
      <div className="itemcontainer">
        <ul>
          { items.map(item =><li key={item.id} className={item.archived ? "archived" : ""} onClick={() => selectClick(item)}>{ item.starred && <span className='icon'><FontAwesomeIcon icon={faStar}/></span> }{item[showProperty]}</li>) }
        </ul>
      </div>
    </div>
  </div>;
}

export default SideList;