import { AllHtmlEntities } from 'html-entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import ReactDOMServer from 'react-dom/server';

const linkIcon = ReactDOMServer.renderToString(<FontAwesomeIcon className="link" icon={faSquareArrowUpRight}></FontAwesomeIcon>);

const entities = new AllHtmlEntities();

export const makeLookupTreeFromItems = (items) => {
  const linkNamesAndIds = items.map(item => ({ id: item.id, name: item.name })).sort((a, b) => b.name.length - a.name.length);

  const lookupTree = new Map();

  const getOrCreateSubtreeFromName = (name) => {
    let subTree = lookupTree;
    for (const c of entities.encode(name).toLowerCase()) {
      const newSubTree = (subTree.get(c) || new Map());
      subTree.set(c, newSubTree);
      subTree = newSubTree;
    }
    return subTree;
  };

  for (const { name, id } of linkNamesAndIds) {
    const subTree = getOrCreateSubtreeFromName(name);
    subTree.set('id', id);
    subTree.set('name', name);
  }

  getOrCreateSubtreeFromName('http://').set('special', 'link');
  getOrCreateSubtreeFromName('https://').set('special', 'link');

  return lookupTree;
}

export const linkFinder = (lookupTree /* : Map */, text, ignoreItemId) => {
  var textWithLinks = entities.encode(text);
  
  let outputString = "";
  let foundLinks = [];
  let stashedChars = []; // string[]
  let stashPos = 0;
  // Note that one traverser does NOT equal one match. One traverser equals one starting position. It can find multiple matches.
  let traversers = []; // { pos: int, subTree: Map }[]
  let currentPos = -1;
  let matches = []; // { start: int, end: int, id: string }
  let isLink = false;
  for (const c of textWithLinks) {
    currentPos += 1;
    stashedChars.push(c);
    if (isLink) {
      if (c === '%' || encodeURI(c) === c) {
        continue;
      } else {
        stashedChars.pop();
        outputString = outputString + `<a title="${entities.decode(stashedChars.join(''))}" target='_blank' href="${entities.decode(stashedChars.join(''))}">${linkIcon}</a>`;
        stashedChars = [c];
        isLink = false;
      }
    }
    traversers.push({ pos: currentPos, subTree: lookupTree });
    let lowestTraverserPos = currentPos+1;
    for (let t = 0; t < traversers.length; t++) {
      const traverser = traversers[t];
      const nextLevel = traverser.subTree.get(c.toLowerCase());
      if (nextLevel === undefined) {
        traversers[t] = null;
        continue;
      }
      const testId = nextLevel.get('id');
      if (testId) {
        if (testId !== ignoreItemId) {
          foundLinks.push({ id: testId, name: nextLevel.get('name') });
          matches.push({ start: traverser.pos, end: currentPos, id: testId, nameLength: nextLevel.get('name').length });
        }
        // NOTE! MUST CONTINUE TRAVERSER
        // Because there may be longer matches from here
      }
      const testSpecial = nextLevel.get('special');
      if (testSpecial === 'link') {
        isLink = true;
        traversers = [];
      }
      
      traverser.subTree = nextLevel;
      if (traverser.pos < lowestTraverserPos) {
        lowestTraverserPos = traverser.pos;
      }
    }

    if (matches.length > 0) {
      const highestMatchEnd = matches.reduce((prevEnd, curr) => curr.end > prevEnd ? curr.end : prevEnd, 0);
      if (highestMatchEnd < lowestTraverserPos) {
        const selectedMatches = [];
        if (matches.length > 1) {
          matches.sort((a, b) => a.nameLength-b.nameLength);
          while (matches.length > 0) {
            const selectedMatch = matches.pop();
            matches = matches.filter(m => m.end < selectedMatch.start || m.start > selectedMatch.end);
            selectedMatches.push(selectedMatch)
          }
        } else {
          selectedMatches.push(matches[0]);
        }
        if (selectedMatches.length > 0) {
          selectedMatches.sort((a, b) => a.start-b.start);
        }
        for (const selectedMatch of selectedMatches) {
          const preceding = stashedChars.slice(0, selectedMatch.start-stashPos).join('');
          const match = stashedChars.slice(selectedMatch.start-stashPos, selectedMatch.end-stashPos+1).join('');
          stashedChars = stashedChars.slice(selectedMatch.end-stashPos+1, undefined);
          stashPos = selectedMatch.end + 1;
          outputString = outputString + preceding + "<a href='#' data-id='" + selectedMatch.id + "'>" + match + "</a>";
        }
        
        matches = [];
      }
    }

    traversers = traversers.filter(t => t !== null);
    const startOfUncommitted = matches.reduce((prevUncommitted, curr) => curr.start < prevUncommitted ? curr.start : prevUncommitted, lowestTraverserPos);
    outputString += stashedChars.slice(0, startOfUncommitted-stashPos).join('');
    stashedChars = stashedChars.slice(startOfUncommitted-stashPos, undefined);
    stashPos = startOfUncommitted;
  }

  const selectedMatches = [];
  matches.sort((a, b) => a.nameLength-b.nameLength);
  while (matches.length > 0) {
    const selectedMatch = matches.pop();
    matches = matches.filter(m => m.end < selectedMatch.start || m.start > selectedMatch.end);
    selectedMatches.push(selectedMatch)
  }
  selectedMatches.sort((a, b) => a.start-b.start);
  for (const selectedMatch of selectedMatches) {
    const preceding = stashedChars.slice(0, selectedMatch.start-stashPos).join('');
    const match = stashedChars.slice(selectedMatch.start-stashPos, selectedMatch.end-stashPos+1).join('');
    stashedChars = stashedChars.slice(selectedMatch.end-stashPos+1, undefined);
    stashPos = selectedMatch.end + 1;
    outputString = outputString + preceding + "<a href='#' data-id='" + selectedMatch.id + "'>" + match + "</a>" ;
  }

  if (isLink) {
    outputString = outputString + `<a title="${entities.decode(stashedChars.join(''))}" target='_blank' href="${entities.decode(stashedChars.join(''))}">${linkIcon}</a>`;
  } else {
    outputString = outputString + stashedChars.join('');
  }

  return { output: outputString, links: foundLinks };
};

export const textHasMatch = (lookupTree /* : Map */, text) => {
  var encodedText = entities.encode(text);
  
  // Note that one traverser does NOT equal one match. One traverser equals one starting position. It can find multiple matches.
  let traversers = []; // { pos: int, subTree: Map }[]
  let currentPos = -1;
  for (const c of encodedText) {
    currentPos += 1;
    traversers.push({ pos: currentPos, subTree: lookupTree });
    for (let t = 0; t < traversers.length; t++) {
      const traverser = traversers[t];
      const nextLevel = traverser.subTree.get(c.toLowerCase());
      if (nextLevel === undefined) {
        traversers[t] = null;
        continue;
      }
      const testId = nextLevel.get('id');
      if (testId) {
        return true;
      }
      traverser.subTree = nextLevel;
    }
    traversers = traversers.filter(t => t !== null);
  }

  return false;
};
